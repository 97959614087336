import React, { useState } from 'react';
import { RedocStandalone } from 'redoc';
import { LoadingSpinner } from './components/LoadingSpinner';
const openapi = require('./openapi.json');

function App() {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <RedocStandalone
        spec={openapi}
        onLoaded={(spec) => {
          setLoading(false);
        }}
        options={{
          hideLoading: true,
          nativeScrollbars: true,
          theme: {
            colors: { primary: { main: '#FF4B00' } },
            typography: {
              fontSize: '14px',
              code: {
                fontSize: '13px',
              },
            },
            spacing: {
              sectionHorizontal: 20,
              sectionVertical: 20,
            },
          },
          // scrollYOffset: 150,
        }}
      />
      {loading && <LoadingSpinner />}
    </>
  );
}

export default App;
