import React from 'react';

export const LoadingSpinner = () => {
  return (
    <div className='flex flex-col items-center justify-center h-screen w-screen'>
      <div className='flex items-center justify-center'>
        <img
          src='/assets/icons/CRAIC_icon_loading.png'
          alt='Loading...'
          className='w-8 h-8 animate-spin-slow'
        />
        <img
          src='/assets/icons/CRAIC_icon_loading.png'
          alt='Loading...'
          className='w-8 h-8 animate-spin-slow mx-2'
        />
        <img
          src='/assets/icons/CRAIC_icon_loading.png'
          alt='Loading...'
          className='w-8 h-8 animate-spin-slow'
        />
      </div>
      <div className='font-bold text-sm text-gray-600 mt-2'>
        Loading Craic API...
      </div>
    </div>
  );
};
